<template>
    <div id="companyContainer">
      <vs-breadcrumb class="mb-5">
        <li><router-link to="/">Domov</router-link><span class="vs-breadcrum--separator">/</span></li>
        <li aria-disabled="page" class="disabled-link">Donacije<span class="vs-breadcrum--separator">/</span></li>
        <li><router-link to="/donacije/podjetja">Donatorska podjetja</router-link><span class="vs-breadcrum--separator">/</span></li>
        <li><router-link :to="'/donacije/podjetja/' + companyId + '/kampanije'">Kampanje donatorskega podjetja <span v-if="companyData">{{companyData.company_name}} ({{companyData.company_display_name}})</span></router-link><span class="vs-breadcrum--separator">/</span></li>
        <li aria-current="page" class="active">{{campaignId ? 'Urejanje' : 'Dodajanje'}} kampanje</li>
      </vs-breadcrumb>
      <CampaignEdit :companyId="companyId" :campaignId="campaignId" @companyDataRetrieved="companyDataRetrieved"></CampaignEdit>
    </div>
</template>

<script>
  import Vue from 'vue';
  import CampaignEdit from "../../components/Sopotniki/donations/CampaignEdit";

  export default {
    name: 'company-campaign-edit',

    components: {
      CampaignEdit
    },




    data() {
      return {
        companyData: null,
        companyId: this.$route.params.companyId ? parseInt(this.$route.params.companyId) : null,
        campaignId: this.$route.params.campaignId ? parseInt(this.$route.params.campaignId) : null,
      }
    },

    beforeRouteLeave(to, from, next) {
      console.log("beforeRouteLeave");
      const _this = this;
      _this.companyId = null;
      _this.campaignId = null;
      setTimeout(() => {next()}, 100);
    },

    computed: {},

    mounted() {
      const _this = this;
      console.log("this ------->", this.$route);


    },

    methods: {
      companyDataRetrieved(companyData) {
        const _this = this;

        console.log("HERE!!!", companyData);

        _this.companyData = companyData;
      }
    }
  }

</script>

