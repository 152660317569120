<template>
  <div id="companyEditContainer" class="relative">

    <div>

      <div class="sm:none md:flex-auto lg:flex xl:flex">

        <div class="sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
          <div class="sm:mr-0 md:mr-0 lg:mr-5 xl:mr-10">

            <div class="vx-row mb-2" v-if="campaignData">
              <div class="vx-col w-full">
                <h1>
                  <span v-if="campaignData && campaignData.id">Uredi kampanijo</span>
                  <span v-if="!campaignData.id">Dodaj kampanijo</span>
                  donatorskega podjetja {{companyData.company_name}} ({{companyData.company_display_name}})
                </h1>
              </div>
            </div>


            <div class="vx-card mt-5 pb-5">
              <div class="vx-card__header">
                <div class="vx-card__title">
                  <h4 class="">Podatki o donatorski kampaniji</h4>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body">

                  <div class="vx-col w-full" v-if="campaignTypes">
                    <label for="donorType" class="vs-input--label">Tip donatorske kampanije</label>
                    <v-select id="donorType" :options="campaignTypes" class=""
                              v-model="campaignTypeId" placeholder="Tip donatorske kampanije"
                              :reduce="item => item.id"
                              label="name">

                      <template v-slot:option="option">
                        <vs-list-item
                          :title="option.name">
                          <template slot="avatar">
                            <vs-avatar size="large" :text="option.name" class="mr-5"/>
                          </template>
                        </vs-list-item>
                      </template>

                      <template v-slot:selected-option="option">
                        <vs-list-item
                          :title="option.name">
                          <template slot="avatar">
                            <vs-avatar size="large" :text="option.name" class="mr-5"/>
                          </template>
                        </vs-list-item>
                      </template>

                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                        </template>
                        <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                      </template>
                    </v-select>

                  </div>

                  <div class="vx-col w-full mt-5" v-if="campaignTypes">
                    <label for="donationRecipientUnit" class="vs-input--label">Enota, ki ji je donacija namenjena</label>
                    <v-select id="donationRecipientUnit" :options="unitsData" class=""
                              v-model="unitId" placeholder="Enota, ki ji je donacija namenjena"
                              :reduce="item => item.id"
                              label="name">

                      <template v-slot:option="option">
                        <vs-list-item
                          :title="option.name"
                          :subtitle="option.coveredMunicipalities">
                          <template slot="avatar">
                            <vs-avatar size="large" :text="option.name.substr(0,1) + (option.name.match(/\d/g) ? option.name.match(/\d/g).join('') : '')" class="mr-5"/>
                          </template>
                        </vs-list-item>
                      </template>

                      <template v-slot:selected-option="option">
                        <vs-list-item
                          :title="option.name"
                          :subtitle="option.coveredMunicipalities">
                          <template slot="avatar">
                            <vs-avatar size="large" :text="option.name.substr(0,1) + (option.name.match(/\d/g) ? option.name.match(/\d/g).join('') : '')" class="mr-5"/>
                          </template>
                        </vs-list-item>
                      </template>

                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                        </template>
                        <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                      </template>
                    </v-select>

                  </div>

                  <div class="grid grid-cols-1 mt-5">
                    <div class="w-full">
                      <label class="vs-input--label">Opis kampanije</label>
                      <vs-textarea class="w-full" rows="6" v-model="campaignData.description"/>
                    </div>
                  </div>

                </div>
              </div>
            </div>


            <div class="vx-card mt-5 pb-5">
              <div class="vx-card__header">
                <div class="vx-card__title">
                  <h4 class="">Naslov lokacije za koriščenje donacije</h4>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body">
                  <div class="grid grid-cols-1">
                    <label for="companyAddress" class="vs-input--label">Naslov lokacije</label>

                    <gmap-autocomplete
                      ref="companyAddress"
                      id="companyAddress"
                      @place_changed="(data) => { getAddressData(data, 'company') }"
                      placeholder="začni vpisovati, nato izberi naslov iz ponujenih možnosti"
                      :value="campaignData.address.quick_search"
                      :options="{componentRestrictions: { country: 'si' }}"
                      :select-first-on-enter="true"
                      class="vs-inputx vs-input--input normal"></gmap-autocomplete>
                  </div>

                  <div class="collapseBody">

                    <div class="flex mt-5">
                      <div class="w-3/4">
                        <vs-input class="w-full" label="Ulica" v-model="campaignData.address.route"/>
                      </div>
                      <div class="w-1/4 ml-5">
                        <vs-input class="w-full" label="Hišna številka"
                                  v-model="campaignData.address.street_number"/>
                      </div>
                    </div>
                    <div class="flex mt-5">
                      <div class="w-3/4">
                        <vs-input class="w-full" label="Naziv pošte" v-model="campaignData.address.postal_town"/>
                      </div>
                      <div class="w-1/4 ml-5">
                        <vs-input class="w-full" label="Poštna številka"
                                  v-model="campaignData.address.postal_code"/>
                      </div>
                    </div>
                    <div class="flex mt-5">
                      <div class="w-3/4">
                        <vs-input class="w-full" label="Občina" v-model="campaignData.address.municipality"/>
                      </div>
                      <div class="w-1/4 ml-5">
                        <vs-input class="w-full" label="Država" v-model="campaignData.address.country"/>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="sm:w-full md:w-full lg:w-1/3 xl:w-1/3">
          <div class="vx-card mt-10" style="overflow: hidden">
            <div class="vx-card__header">
              <h4 class="text-success mb-4">Lokacija koriščenje donacije</h4>
            </div>
            <div class="vx-card__collapsible-content">
              <div class="vx-card__body p-0">

                <gmap-map ref="map" :center="center" :zoom="15" style="width: 100%; height: 500px">

                  <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false"/>
                  <GmapMarker
                    :key="index"
                    v-for="(item, index) in markers"
                    :position="item.position"
                    :clickable="true"
                    :draggable="false"
                    @click="toggleInfoWindow(item, index)"
                  />

                  <DirectionsRenderer/>



                </gmap-map>

              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="flex mt-10 mb-20">
        <div class="w-1/2">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
            <vs-button type="border" icon="cancel" @click="$router.go(-1)" class="mt-2">Prekliči</vs-button>
          </vs-col>
        </div>
        <div class="w-1/2">
          <div class="flex">
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
              <vs-button size="large" color="danger" icon="delete">Izbriši</vs-button>
            </vs-col>

            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
              <vs-button size="large" color="success" icon="save" @click="saveCampaign">Shrani</vs-button>
            </vs-col>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
  import Vue from 'vue';
  import DirectionsRenderer from "../../../helpers/DirectionsRenderer";
  import vSelect from "vue-select";
  import Fuse from 'fuse.js'
  import Datepicker from 'vuejs-datepicker';
  import {slSI} from 'vuejs-datepicker/dist/locale';
  import flatPickr from 'vue-flatpickr-component';
  import 'flatpickr/dist/flatpickr.css';

  let directionsService = null;
  let directionsDisplay = null;
  let geoCoder = null;

  export default {
    name: 'campaign-edit',

    components: {
      DirectionsRenderer,
      'v-select': vSelect,
      'datepicker': Datepicker,
      'flat-pickr': flatPickr,
    },
    props: {
      companyId: Number,
      campaignId: Number
    },
    data() {
      return {
        slSI: slSI,

        center: {lat: 46.0569, lng: 14.5058},

        logo: null,

        infoWindowPos: null,
        infoWinOpen: false,
        currentMidx: null,

        infoOptions: {
          content: '',
          pixelOffset: {
            width: 0,
            height: -35
          }
        },

        markers: [],

        campaignTypes: [],
        campaignTypeId: null,

        unitsData: [],
        unitId: null,

        companyData: {

        },
        campaignData: {
          campaign_type: null,
          created_at: null,
          deleted: null,
          description: null,
          disabled: null,
          donor_id: null,
          id: null,
          schedules: null,
          unit_id: null,
          updated_at: null,
          address: {
            quick_search: null
          }
        }
      }
    },

    computed: {
      getToken() {
        const token = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).token : null;
        if (token) {
          return token
        }
      }
    },

    mounted() {
      const _this = this;

      console.log("collapse", _this.$refs.collapsible);

      _this.checkIfGoogleMapsApiLoaded().then((res) => {
        console.log("DONE");

        if (_this.campaignId) {
          _this.$vs.loading();

          _this.getUnits().then(() => {
            _this.getCompany().then(() => {
              _this.getCampaignTypes().then(() => {
                _this.getCampaign();
              });
            });
          });
        } else {
          console.log("kaj pa zdej!?!");
          // _this.$vs.loading.close();
          _this.getUnits().then(() => {
            _this.getCompany().then(() => {
              _this.getCampaignTypes();
            });
          });
        }
      });
    },

    watch: {
      companyId: {
        handler(val, oldVal) {
          console.log("CHANGE!!!!");

          if (val) {
            this.getCompany();
          }
        }
      }
    },

    methods: {
      async checkIfGoogleMapsApiLoaded() {
        const _this = this;

        let promise = new Promise(function (resolve, reject) {
          if (typeof google !== 'undefined') {
            resolve("done")
          } else {
            setTimeout(() => {
              _this.checkIfGoogleMapsApiLoaded()
            }, 400);
          }
        });
      },

      async getUnits() {
        const _this = this;

        _this.$vs.loading();

        await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'units')
          .then((res) => {

            _this.unitsData = res.data.data;
            _this.unitsData.push({
              id: -1,
              name: 'Sedež Zavoda Sopotniki',
              coveredMunicipalities: 'Sedež Zavoda Sopotniki'
            })
            console.log("unitsData: ", res.data.data);

            _this.$vs.loading.close();

          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });

      },

      async getCompany() {
        const _this = this;

        _this.$vs.loading();

        await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'donors/' + _this.companyId)
          .then((res) => {

            _this.companyData = res.data.data; //_.filter(res.data.data, {id: _this.companyId})[0];
            _this.$emit('companyDataRetrieved', res.data.data);
            _this.donorTypeId = res.data.data.donor_type_id;
            console.log("TOLE: ", _this.campaignData);

            _this.$vs.loading.close();


          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      },

      async getCampaignTypes() {
        const _this = this;

        _this.$vs.loading();

        await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'campaigns/types/')
          .then((res) => {

            _this.campaignTypes = res.data.data;

            console.log("campaignTypes: ", _this.campaignTypes);
            _this.$vs.loading.close();
          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      },

      async getCampaign() {
        const _this = this;

        _this.$vs.loading();

        await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'campaigns?donor_id=' + _this.companyId)
          .then((res) => {

            _this.campaignData = _.filter(res.data.data, {id: _this.campaignId})[0];
            _this.unitId = _this.campaignData.unit_id === null ? -1 : _this.campaignData.unit_id;
            _this.campaignTypeId = _this.campaignData.campaign_type;
            console.log("campaignData: ", _this.campaignData);
            _this.$vs.loading.close();

            _this.showCampaignMarker();

          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      },

      setMapsDirections() {
        const _this = this;

        if (!directionsService) {
          directionsService = new google.maps.DirectionsService;
          directionsDisplay = new google.maps.DirectionsRenderer;
          directionsDisplay.setMap(_this.$refs.map.$mapObject);
        }
      },


      getAddressData(addressData, addressSection) {
        const _this = this;

        console.log("addressData", addressData);

        document.getElementById(addressSection + "Address").value = addressData.formatted_address;
        _this.campaignData.address.quick_search = addressData.formatted_address;
        _this.campaignData.address.route = addressData.address_components[1].long_name;
        _this.campaignData.address.street_number = addressData.address_components[0].long_name;
        _this.campaignData.address.postal_town = addressData.address_components[2].long_name;
        _this.campaignData.address.postal_code = addressData.address_components[5].long_name;
        _this.campaignData.address.municipality = addressData.address_components[3].long_name;
        _this.campaignData.address.country = addressData.address_components[4].long_name;


        if (!directionsService) {
          directionsService = new google.maps.DirectionsService;
          directionsDisplay = new google.maps.DirectionsRenderer;
          directionsDisplay.setMap(_this.$refs.map.$mapObject);
        }

        let position = {
          'lat': addressData.geometry.location.lat(),
          'lng': addressData.geometry.location.lng()
        };

        _this.markers[0] = {
          infoText: 'Lokacija koriščenja donacije',
          position: position
        };
        _this.center = position;

        _this.$forceUpdate();

        _this.setMapsDirections();

      },

      showCampaignMarker() {
        const _this = this;

        geoCoder = new google.maps.Geocoder();
        geoCoder.geocode({'address': _this.campaignData.address.quick_search}, function (results, status) {
          if (status === 'OK') {
            _this.center = results[0].geometry.location;

            let position = {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            }

            _this.markers.push({
              infoText: 'Lokacija koriščenja donacije',
              position: position
            });

            _this.setMapsDirections();

          } else {
            console.error('Geocode was not successful for the following reason: ' + status);
          }
        });

      },

      customDateFormatter(date) {
        return this.moment(date).format('DD. MM. YYYY');
      },

      saveCampaign() {
        const _this = this;

        let campaign = _.omit(_this.campaignData, ['campaigns', 'donorType', 'disabled', 'deleted', 'created_at', 'updated_at']);
        campaign.donor_id = _this.companyId;
        campaign.addressObject = campaign.address;
        campaign.campaign_type = _this.campaignTypeId;
        if(_this.unitId === -1 || _this.unitId === null) {
          campaign.unit_id = null;
        } else {
          campaign.unit_id = _this.unitId;
        }

        _this.$vs.loading();

        console.log("campaign data to save", campaign);

        Vue.prototype.$http.post(Vue.prototype.$config.api.productionNew + 'campaigns', campaign)
          .then((res) => {

            console.log("res", res);
            _this.$vs.loading.close();
            _this.$router.push({name: 'Kampanije', params: {companyId: _this.companyId}});

          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });

      },

      toggleInfoWindow: function (marker, idx) {
        this.infoWindowPos = marker.position;
        this.infoOptions.content = marker.infoText;

        if (this.currentMidx == idx) {
          this.infoWinOpen = !this.infoWinOpen;
        } else {
          this.infoWinOpen = true;
          this.currentMidx = idx;

        }
      }

    }
  }
</script>

